import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        '& .quoteContainer': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                flexWrap: 'wrap',
            },
        },
        '& .quoteContainer > *': {
            margin: '0px 5px',
        },

        '& .wpx-sub': {
            fontWeight: 'bold',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        '& p': {
            fontSize: 12,
            [theme.breakpoints.down('sm')]: {
                fontSize: 10,
            },
        },

        '& .wpx-link': {
            fontSize: 12,
            textDecoration: 'underline',
            cursor: 'pointer',
            [theme.breakpoints.down('sm')]: {
                fontSize: 10,
            },
        },
    },
    arrow: {
        fontSize: '1.5rem',
        position: 'absolute',
        padding: 10,
        borderRadius: 5,
        textAlign: 'center',
        paddingLeft: 10,
        top: 'calc(50% - 1.5rem)',
        width: 30,
        height: 30,
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            width: 20,
            height: 20,
            fontSize: '1rem',
            top: 'calc(50%)',
        },
    },
    leftArrow: {
        left: '20%',
        [theme.breakpoints.down('sm')]: {
            left: 5,
        },
    },

    btnClose: {
        position: 'absolute',
        top: 6,
        right: 15,
        color: 'inherit',
        borderRadius: 5,
        border: 'none',
        cursor: 'pointer',
        outline: 'none',
        background: 'none',
        [theme.breakpoints.down('sm')]: {
            top: 3,
            right: 5,
        },
    },

    rightArrow: {
        right: '20%',
        [theme.breakpoints.down('sm')]: {
            right: 10,
        },
    },
    tabs: {
        boxShadow: 'none',
        backgroundColor: '#FFFFFF',
        alignItems: 'left',
        // marginTop: '56px',
        borderBottom: '1px solid #E5E7EB',
        '& .tabsHeader': {
            letterSpacing: '0.75px',
            fontWeight: '600',
            color: '#9CA3AF',
            textTransform: 'capitalize',
            '& .MuiTab-wrapper': {
                fontSize: '14px',
                lineHeight: '20px',
                alignItems: 'start',
            },
            '&.Mui-selected': {
                color: '#000000',
            },
            '&.MuiTab-root': {
                marginRight: '16px',
                padding: 0,
                minWidth: 'auto',
            },
        },
    },
    tabPanelContainer: {
        '& .MuiBox-root': {
            padding: '16px 0 16px 0',
        },
        '& .MuiListItem-gutters': {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    customGridClass: {
        '& .MuiGrid-item': {
            paddingBottom: '16px',
        },
    },
    titleCms: {
        [theme.breakpoints.up('sm')]: {
            paddingTop: '16px',
        },
    },
}));

export default useStyles;
